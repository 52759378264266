var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-box"},[(_vm.showDataType == 'myGroup')?_c('div',{staticClass:"menu-item",attrs:{"id":"leftGroupList"}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showMyAddGroupList)?_c('div',{staticClass:"menu-item-child-box"},_vm._l((_vm.myGroupData),function(item,index){return _c('div',{key:index,staticClass:"group-message-box clearfix",class:_vm.checkGroupData!=null && _vm.checkGroupData.id == item.id ? 'group-message-box-check' : '',on:{"click":function($event){$event.stopPropagation();return _vm.checkGroupFile(item)}}},[_c('div',{staticClass:"group-new-img",style:({
                          width: 45 + 'px',
                          height: 45 + 'px',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          overflow: 'hidden',
                          backgroundImage: 'url('+ _vm.httpImageToPrefix(item.cover) +')',
                      }),on:{"click":function($event){$event.stopPropagation();return _vm.showGroupSetting(item)}}}),_c('div',{staticClass:"group-new-message"},[_c('div',{staticClass:"group-name"},[_c('div',{staticClass:"group-name-text"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"group-new-time group-right-new-time"},[_vm._v(" "+_vm._s(_vm.timestampToTimeDHM(item.updateTime ))+" "),(item.isTop)?_c('img',{staticStyle:{"margin-top":"-4px","width":"12px"},attrs:{"src":require("../../assets/img/groupImg/setTop.svg"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"group-new-time",staticStyle:{"width":"251px"}},[_vm._v(" "+_vm._s(item.subtitle)+" ")])]),_c('div',{staticClass:"more-menu",on:{"mouseover":function($event){return _vm.moveMenu($event,true,item)},"mouseout":function($event){return _vm.moveMenu($event,false,item)}}},[_c('img',{staticClass:"more-menu-icon",attrs:{"src":require("../../assets/img/groupImg/more.svg"),"alt":""}})]),(item.unread  == true )?_c('div',{staticClass:"message-hint"},[_c('img',{attrs:{"src":require("../../assets/img/groupImg/redFlag.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_There_Is_A_New_Content))+" ")]):_vm._e(),(index != _vm.myGroupData.length )?_c('div',{staticClass:"group-bottom-line"}):_vm._e()])}),0):_vm._e()])],1):(_vm.showDataType == 'searchMyGroup')?_c('div',{staticClass:"menu-item"},[_c('div',{staticClass:"menu-item-child-box"},_vm._l((_vm.searchGroupData),function(item,index){return _c('div',{key:index,staticClass:"group-message-box clearfix",class:_vm.checkGroupData!=null && _vm.checkGroupData.id == item.id ? 'group-message-box-check' : '',on:{"click":function($event){$event.stopPropagation();return _vm.checkGroupFile(item)}}},[_c('div',{staticClass:"group-new-img",style:({
                          width: 45 + 'px',
                          height: 45 + 'px',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          overflow: 'hidden',
                          backgroundImage: 'url('+ _vm.httpImageToPrefix(item.cover) +')',
                      }),on:{"click":function($event){$event.stopPropagation();return _vm.showGroupSetting(item)}}}),_c('div',{staticClass:"group-new-message"},[_c('div',{staticClass:"group-name"},[_c('div',{staticClass:"group-name-text"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.isTop == true )?_c('div',{staticClass:"group-top"},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Stick))+" ")]):_vm._e()]),_c('div',{staticClass:"group-new-time"},[_vm._v(" "+_vm._s(_vm.timestampToTimeDHM(item.updateTime ))+" ")]),_c('div',{staticClass:"group-new-time",staticStyle:{"width":"251px"}},[_vm._v(" "+_vm._s(item.subtitle)+" ")])]),_c('div',{staticClass:"more-menu",on:{"mouseover":function($event){return _vm.moveMenu($event,true,item)},"mouseout":function($event){return _vm.moveMenu($event,false,item)}}},[_c('img',{staticClass:"more-menu-icon",attrs:{"src":require("../../assets/img/groupImg/more.svg"),"alt":""}})]),(item.unread  == true )?_c('div',{staticClass:"message-hint"},[_c('img',{attrs:{"src":require("../../assets/img/groupImg/redFlag.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_There_Is_A_New_Content))+" ")]):_vm._e(),(index != _vm.searchGroupData.length )?_c('div',{staticClass:"group-bottom-line"}):_vm._e()])}),0)]):_vm._e(),_c('GroupMoreMenu',{attrs:{"showData":_vm.menuList,"fullHeight":_vm.fullHeight,"pointObj":_vm.pointObj},on:{"checkMenuItem":_vm.checkMenuItem}}),_c('GroupInviteRembers',{attrs:{"groupDataDetail":_vm.checkGroupDataDetail,"show":_vm.showInput},on:{"hiddenInput":_vm.hiddenInput}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }