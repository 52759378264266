var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-img",style:({ height: _vm.imgHeight + 'px' })},[void 0,(_vm.isMember)?_c('div',{staticClass:"member-style",style:({ height: _vm.imgHeight + 'px' })},[_c('img',{staticClass:"member-crown",style:({
                    width: _vm.imgWidth + 'px',
                }),attrs:{"src":require("../../assets/img/user/crown.png"),"alt":""}}),_c('div',{staticClass:"group-new-img",style:({
                    width: _vm.imgWidth + 'px',
                    height: _vm.imgWidth + 'px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    overflow: 'hidden',
                    backgroundImage: 'url('+ _vm.httpImageToPrefix(_vm.imgUrl) +')',
                })})]):_c('div')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }