var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],staticClass:"group-add-new-group",on:{"click":function($event){$event.stopPropagation();return _vm.hidden(false)}}},[_c('div',{staticClass:"center-writer",on:{"click":function($event){$event.stopPropagation();return _vm.hidden(true)}}},[_c('div',{staticClass:"close-modal",on:{"click":function($event){$event.stopPropagation();return _vm.hidden(false)}}},[_c('a-icon',{attrs:{"type":"close"}})],1),_c('div',{staticClass:"search-header-input"},[_c('div',{staticClass:"search-left-search"},[_c('a-button',{staticClass:"search-search-button",attrs:{"shape":"circle","icon":"search","size":"small"},on:{"click":function($event){return _vm.searchButton()}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],staticClass:"search-search-box",attrs:{"type":"text","placeholder":_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Please_Enter_The_Group_Number)},domProps:{"value":(_vm.searchText)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchButton()},"input":function($event){if($event.target.composing)return;_vm.searchText=$event.target.value}}}),(_vm.searchText != '')?_c('div',{staticClass:"cleariconfont search-right-icon",on:{"click":function($event){return _vm.clearSearchText()}}},[_vm._v("")]):_vm._e()],1)]),_c('div',{staticClass:"search-group-list"},[_c('div',{staticClass:"menu-item"},[_c('div',{staticClass:"menu-item-child-box"},[(_vm.searchGroupData != null && _vm.searchGroupData.length > 0)?_vm._l((_vm.searchGroupData),function(item,index){return _c('div',{key:index,staticClass:"group-message-box clearfix",staticStyle:{"z-index":"1"},on:{"click":function($event){return _vm.jumpDetails(item)}}},[_c('div',{staticClass:"group-new-img",style:({
                                        width: 45 + 'px',
                                        height: 45 + 'px',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center center',
                                        overflow: 'hidden',
                                        backgroundImage: 'url('+ _vm.httpImageToPrefix(item.cover) +')',
                                    })}),_c('div',{staticClass:"group-new-message"},[_c('div',{staticClass:"group-name"},[_c('div',{staticClass:"group-name-text"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"group-new-time"},[_vm._v(" "+_vm._s(_vm.timestampToTimeDHM(item.updateTime ))+" ")]),_c('div',{staticClass:"group-new-time"},[_vm._v(" "+_vm._s(item.subtitle)+" ")])]),(!item.isJoined)?[(item.mbeansRequired && item.mbeansRequired != 0)?_c('div',{staticClass:"right-add-button",attrs:{"onClick":"event.cancelBubble = true"},on:{"click":function($event){return _vm.applyButton(item)}}},[_c('label',{staticClass:"cursor-pointer"},[_vm._v(_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Immediately_To_Join)))]),_c('label',{staticClass:"cursor-pointer",staticStyle:{"margin-left":"4px"}},[_vm._v(_vm._s(item.mbeansRequired))]),_c('img',{staticStyle:{"margin":"-2px 0 0 2px"},attrs:{"src":require("../../assets/img/user/member/mBean.svg"),"alt":"","width":"14px"}})]):(item.needVerification)?_c('div',{staticClass:"right-add-button",attrs:{"onClick":"event.cancelBubble = true"},on:{"click":function($event){return _vm.applyButton(item)}}},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Apply_To_Join))+" ")]):_c('div',{staticClass:"right-add-button",attrs:{"onClick":"event.cancelBubble = true"},on:{"click":function($event){return _vm.applyButton(item)}}},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Immediately_To_Join))+" ")])]:_vm._e()],2)}):_vm._e(),(_vm.searchGroupData == null || _vm.searchGroupData.length == 0)?[_c('img',{staticClass:"null-message",attrs:{"src":require("../../assets/img/groupImg/noMessage/nullSearch.svg"),"alt":""}}),_c('div',{staticClass:"null-message-text"},[_vm._v("搜索没有结果哟~")])]:_vm._e()],2)])])]),_c('ApplyJoinGroup',{attrs:{"tagModalShow":_vm.showInput,"groupItem":_vm.checkGroup},on:{"hideModal":_vm.hideModal,"applyJoinGroup":_vm.applyJoinGroup}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }